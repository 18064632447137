import { documentId, query, where } from "firebase/firestore/lite";
import { myCode, resetMyData } from "./code";
import {
  buyItemFirebaseFunc,
  collectionsRef,
  execFunction,
  getDocsForQuery,
  itemsRef,
  winFirebaseFunc,
} from "./firebase";
import log from "../helper/Logger";

export async function getMyItems() {
  const myItems = await getDocsForQuery(
    query(collectionsRef, where("code", "==", myCode()))
  );
  log.debug("these are the items from firebase", myItems);
  if (!myItems || !myItems.length) {
    return [];
  }
  const itemsSortedByDate = myItems.sort((a, b) => {
    const dateA = a.date.toDate();
    const dateB = b.date.toDate();
    if (dateA > dateB) return 1;
    if (dateA < dateB) return -1;
    return 0;
  });

  log.debug("these are the sorted items from firebase", itemsSortedByDate);

  const itemsLoaded = await getItems(
    itemsSortedByDate.map((item) => {
      return item.itemId;
    })
  );

  // need to sort again because items return unsorted
  return itemsLoaded.sort((a, b) => {
    const indexA = itemsSortedByDate.map((x) => x.itemId).indexOf(a.id);
    const indexB = itemsSortedByDate.map((x) => x.itemId).indexOf(b.id);
    return indexA - indexB;
  });
}

export async function getItems(ids) {
  log.debug("itemsRef", ids);
  return await getDocsForQuery(query(itemsRef, where(documentId(), "in", ids)));
}

export async function getAllItems() {
  return await getDocsForQuery(query(itemsRef, where("name", "!=", false)));
}

export async function getItem(itemId) {
  const items = await getItems([itemId]);
  if (items && items.length) {
    return items[0];
  }
  return undefined;
}

export async function win(placeId) {
  return await execFunction(winFirebaseFunc, { code: myCode(), placeId });
}

export async function buyItem(itemId) {
  resetMyData();
  return await execFunction(buyItemFirebaseFunc, { code: myCode(), itemId });
}
