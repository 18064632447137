import { useEffect } from "react";
import { useState } from "react";
import QRCode from "react-qr-code";
import { getAllPlaces } from "../services/places";
import log from "../helper/Logger";

//export const serverURL = 'http://192.168.50.106:3000' // process.env.REACT_APP_API_SERVER
export const serverURL = "https://piratenschule-tgt.freisicht.ch"; // process.env.REACT_APP_API_SERVER

export function AllPlacesView() {
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    async function asy() {
      const p = await getAllPlaces();
      setPlaces(p);
      log.debug("fetched places", p);
    }
    asy();
  }, []);

  return (
    <div className="flex flex-wrap">
      {places.map((place) => {
        const url = serverURL + "/win/" + place.id;
        return (
          <div key={place.id} className="bg-gray p-4 border w-64 h-64">
            <div className="font-bold">{place.name}</div>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={url}
              viewBox={`0 0 256 256`}
            />
            <div>difficulty: {place.difficulty}</div>
          </div>
        );
      })}
    </div>
  );
}
