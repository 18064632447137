import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { CodeProvider } from "./components/CodeProvider";
import { AllPlacesView } from "./components/AllPlacesView";
import { FoundLootView } from "./components/FoundLootView";
import { CollectionView } from "./components/treasures/CollectionView";
import { AllItemsView } from "./components/treasures/AllItemsView";
import { HarbourView } from "./components/harbour/HarbourView";
import { MapView } from "./components/map/MapView";
import { MyItemView } from "./components/MyItemView";
import { FiguresView } from "./components/harbour/FiguresView";
import { AboutView } from "./components/harbour/AboutView";
import { RulesView } from "./components/harbour/RulesView";
import { DiplomView } from "./components/harbour/DiplomView";
import { HelpView } from "./components/harbour/HelpView";
import { LearnGoalsView } from "./components/harbour/LearnGoalsView";
import { FigureView } from "./components/FigureView";
import { LevelFinished } from "./components/diplom/LevelFinished";
import { MapOnlyView } from "./components/map/MapOnlyView";
import { OnboardingView } from "./components/start/OnboardingView";
import { useEffect } from "react";
import { usePirateStore } from "./store/usePirateStore";
import { FirebaseAnalytics } from "./services/firebaseAnalytics";
import { IntroView } from "./components/harbour/IntroView";
import { HistoryView } from "./components/harbour/HistoryView";
import { WonEverything } from "./components/end/WonEverything";

export default function PiratenRouter() {
  const setInstallPrompt = usePirateStore((state) => state.setInstallPrompt);

  useEffect(() => {
    const handler = (event) => {
      event.preventDefault();
      console.log("bla beforeinstallprompt event fired");
      setInstallPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  const PiratenRoutes = () => {
    const location = useLocation();
    return (
      <AnimatePresence mode="wait" initial={false}>
        <Routes location={location} key={location.key}>
          <Route element={<CodeProvider />}>
            <Route element={<CollectionView />} path="/" exact />
            <Route element={<CollectionView />} path="/coll" />
            <Route element={<AllItemsView />} path="/all_items" />
            <Route element={<MyItemView />} path="/item/:itemId" />
            <Route element={<FigureView />} path="/figure/:figureId" />
            <Route element={<HarbourView />} path="/harbour" />
            <Route element={<HelpView />} path="/harbour/help" />
            <Route element={<IntroView />} path="/harbour/intro" />
            <Route element={<DiplomView />} path="/harbour/diplom" />
            <Route element={<LearnGoalsView />} path="/harbour/goals" />
            <Route element={<RulesView />} path="/harbour/rules" />
            <Route element={<AboutView />} path="/harbour/about" />
            <Route element={<FiguresView />} path="/harbour/figures" />
            <Route element={<HistoryView />} path="/harbour/history" />
            <Route element={<CollectionView />} path="/collection" />
            <Route element={<FoundLootView />} path="/win/:placeId" />
            <Route element={<MapView />} path="/map" />
            <Route element={<LevelFinished />} path="/leveldone" />
            <Route element={<WonEverything />} path="/endgame" />
          </Route>
          <Route element={<MapOnlyView />} path="/maponly" />
          <Route element={<OnboardingView />} path="/onboarding" />
          <Route element={<AllPlacesView />} path="/qr" />
          <Route element={<Navigate to="/" />} path="*" />
          <Route />
        </Routes>
      </AnimatePresence>
    );
  };

  return (
    <Router>
      <FirebaseAnalytics />
      <PiratenRoutes />
    </Router>
  );
}
