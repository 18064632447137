import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePirateStore } from "../store/usePirateStore";
import { ItemView } from "./ItemView";

export function MyItemView() {
  const resetNewItem = usePirateStore((state) => state.resetNewItem);
  const prevRoute = usePirateStore((state) => state.prevRoute);
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;
  console.log("prevRoute ", prevRoute);
  const params = useParams();
  let { itemId } = params;
  const navigate = useNavigate();
  function onClose() {
    resetNewItem();
    if (prevRoute === "/all_items") {
      setPrevRoute(location);
      navigate("/all_items");
    } else {
      setPrevRoute(location);
      navigate("/");
    }
  }

  return <ItemView onClose={onClose} itemId={itemId} />;
}
