// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import log from "../helper/Logger";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// config for dev
const firebaseConfig = {
  apiKey: "AIzaSyDKSfgCJ7xXBuMeN-TpwU-MUvc_Cr4YV3Y",
  authDomain: "piratenschule-f3b52.firebaseapp.com",
  projectId: "piratenschule-f3b52",
  storageBucket: "piratenschule-f3b52.appspot.com",
  messagingSenderId: "454820310866",
  appId: "1:454820310866:web:8ba36bcf5b069d04dbd48b",
  measurementId: "G-JH56K130N4",
};

// config for prod
// const firebaseConfig = {
//   apiKey: "AIzaSyDw-_BsMSLhAiH6qZCRxqcOekJsQzz2jfU",
//   authDomain: "piratenschule-prod.firebaseapp.com",
//   projectId: "piratenschule-prod",
//   storageBucket: "piratenschule-prod.appspot.com",
//   messagingSenderId: "745653929753",
//   appId: "1:745653929753:web:f7dccd17c003bac1116788",
//   measurementId: "G-VMZ5Z9ZY91",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

const db = getFirestore(app);

export const functions = getFunctions(app, "europe-west6");

// use this to test functions locally
// connectFunctionsEmulator(functions, "localhost", 5001);
export const placesRef = collection(db, "places");
export const collectionsRef = collection(db, "collections");
export const itemsRef = collection(db, "items");
export const itemsCombinationsRef = collection(db, "itemsCombinations");

export const winFirebaseFunc = httpsCallable(functions, "play");
export const generateCodeFirebaseFunc = httpsCallable(
  functions,
  "generateCode"
);
export const sendCodeFirebaseFunc = httpsCallable(functions, "sendCode");
export const setUserCredentialsFirebaseFunc = httpsCallable(
  functions,
  "setUserCredentials"
);
export const buyItemFirebaseFunc = httpsCallable(functions, "buyItem");
export const sendDiplomaFirebaseFunc = httpsCallable(functions, "sendDiploma");
export const checkCodeFirebaseFunc = httpsCallable(functions, "checkCode");
export const getMyDataFirebaseFunc = httpsCallable(functions, "getMyData");

export async function getDocsForQuery(q) {
  const dataSnapshot = await getDocs(q);
  return (
    dataSnapshot &&
    dataSnapshot.docs &&
    dataSnapshot.docs.map &&
    dataSnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    })
  );
}

export async function execFunction(f, data) {
  try {
    const result = await f(data);
    const rdata = result.data;
    return rdata.result;
  } catch (e) {
    log.warn("error executing function");
  }
  return undefined;
}
