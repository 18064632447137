import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { usePirateStore } from "../../store/usePirateStore";
import { IslandBackgroundView } from "./IslandBackgroundView";
import text from "../texts/allTexts.json";
import {
  FADE_TRANSITION,
  FADE_VARIANTS,
  getFadeDirection,
} from "../../helper/PageAnimation";
import { useState } from "react";

const modeStyle = {
  collection: {
    bg: "/bg_bright.jpg",
    islands: "/bg_inseln_collection_wide.png",
    textColor: "text-primary",
  },
  harbour: {
    bg: "/bg_bright.jpg",
    islands: "/bg_inseln_harbour_wide.png",
    textColor: "text-primary",
  },
  map: {
    bg: "/bg_bright.jpg",
    islands: "/bg_inseln_map.png",
    textColor: "text-primary",
  },
};

export function ScreenWithBar({
  children,
  activeTab = "",
  title,
  rightSide,
  fullWidthContent,
  backgroundStyle = "collection",
  animate = true,
  animationOut = "fadeout",
}) {
  const setPrevRoute = usePirateStore((state) => state.setPrevRoute);
  const location = useLocation().pathname;

  const prevRoute = usePirateStore((state) => state.prevRoute);
  const [dynamicOutAnimation, setDynamicOutAnimation] = useState(animationOut);
  const dynamicInAnimation = getFadeDirection(prevRoute, location, "in");
  const navigate = useNavigate();
  const icons = [
    {
      path: "harbour",
      icon: "app_icons/DefaultIconHafen.svg",
      iconActive: "app_icons/SelectedIconHafen.svg",
      text: text.navigation.harbour,
    },
    {
      path: "",
      icon: "app_icons/DefaultIconSchaetze.svg",
      iconActive: "app_icons/SelectedIconSchaetze.svg",
      text: text.navigation.main,
    },
    {
      path: "map",
      icon: "app_icons/DefaultIconKarte.svg",
      iconActive: "app_icons/SelectedIconKarte.svg",
      text: text.navigation.map,
    },
  ];

  return (
    <IslandBackgroundView
      style={{ ...modeStyle[backgroundStyle], paddingTop: "pt-4" }}
      animationIn={dynamicInAnimation}
      animationOut={dynamicOutAnimation}
    >
      {title && (
        <motion.div
          initial={dynamicInAnimation}
          animate={"default"}
          exit={dynamicOutAnimation}
          transition={FADE_TRANSITION}
          variants={FADE_VARIANTS}
        >
          <div className="grid grid-cols-3">
            <div className="headerFont col-span-2 font-bold text-3xl text-left">
              {title}
            </div>
            {rightSide && rightSide}
          </div>
        </motion.div>
      )}

      <div
        className={
          "flex-grow text-xl overflow-y-scroll overflow-x-hidden scrollbar-hide " +
          fullWidthContent
        }
      >
        {animate ? (
          <motion.div
            initial={dynamicInAnimation}
            animate={"default"}
            exit={dynamicOutAnimation}
            transition={FADE_TRANSITION}
            variants={FADE_VARIANTS}
          >
            {children}
          </motion.div>
        ) : (
          <>{children}</>
        )}
      </div>

      <div className="flex flex-row justify-around place-content-center pb-2 pt-1 text-4xl">
        {icons.map((iconConfig, i) => {
          return (
            <div
              key={i}
              onClick={() => {
                setPrevRoute(location);
                const fadeDirection = getFadeDirection(
                  location,
                  "/" + iconConfig.path,
                  "out"
                );
                console.log("blablADSFADF, ", fadeDirection);

                setDynamicOutAnimation(fadeDirection);
                navigate("/" + iconConfig.path);
              }}
            >
              <img
                src={
                  activeTab === iconConfig.path
                    ? iconConfig.iconActive
                    : iconConfig.icon
                }
                className="w-16 -mb-2"
                alt={iconConfig.text}
              />
              <div
                className={
                  activeTab === iconConfig.path
                    ? "text-xs text-accent"
                    : "text-xs text-primary"
                }
              >
                {iconConfig.text}
              </div>
            </div>
          );
        })}
      </div>
    </IslandBackgroundView>
  );
}
